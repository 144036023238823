import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AgentTable from '../components/AgentTable';
import '../App.css';

const sampleDataCoding = [
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1720660807926-a91ad7cb56c77178.jpg",
    "title": "Micro Agent",
    "description": "An AI Agent that writes and fixes test-driven code from natural language descriptions.",
    "rating": 4.8
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1722508534325-152b1f70015dc0f7.png",
    "title": "KushoAI",
    "description": "Get 100+ ready-to-run functional tests for your APIs in minutes",
    "rating": 4.7
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1722048937732-dcf3e9e78a9eb3a5.jpg",
    "title": "Aider",
    "description": "AI pair programming tool for terminal-based code editing with LLMs.",
    "rating": 4.6
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1720413364713-64d882d615cb79b7.png",
    "title": "Tusk",
    "description": "AI coding agent that automates bug fixes and product quality tasks.",
    "rating": 4.5
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1722388097722-1684d73c3a1047e6.jpg",
    "title": "Cursor",
    "description": "An AI-powered code editor designed to enhance productivity through intelligent code suggestions and",
    "rating": 4.4
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1721575281522-83a820c20231a4fa.png",
    "title": "SoftGen",
    "description": "AI agent that creates web applications from natural language descriptions in minutes.",
    "rating": 4.3
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1728103884271-91d9c5130e1a775d.jpg",
    "title": "Bolt",
    "description": "AI-powered full-stack web development platform",
    "rating": 4.2
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1720674600141-65d1aa55e5faa8e1.jpg",
    "title": "GitHub Copilot",
    "description": "AI coding agent that helps developers write code faster and smarter.",
    "rating": 4.1
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1721882922869-a0044eb64ea49852.png",
    "title": "Sourcegraph Cody AI",
    "description": "AI coding assistant that enhances developer productivity with context-aware",
    "rating": 4.0
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1724952461888-63017064a35e7714.png",
    "title": "Supermaven",
    "description": "AI-powered code completion tool for enhanced developer productivity.",
    "rating": 4.0
  }
];

const sampleDataProductivity = [
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1727646696892-b90b3359b3d04ac9.png",
    "title": "Supercog",
    "description": "Supercog is the \"connected agent\" platform for productivity at work.",
    "rating": 4.8
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1724748497161-46f89a94626e8f40.png",
    "title": "Agent E",
    "description": "State-of-the-art web agent automating tasks on your local browser",
    "rating": 4.7
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1721936587125-62d6611595bc8d41.png",
    "title": "Composio",
    "description": "A platform for integrating AI agents with over 150 external tools to enhance functionality.",
    "rating": 4.6
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1721671819275-d8def1e02624c804.png",
    "title": "Taskade",
    "description": "Productivity platform that automate tasks, manage projects, and collaborate using AI agents.",
    "rating": 4.5
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1721964350178-bc529588dff0564f.jpg",
    "title": "HyperWrite AI Agent",
    "description": "AI-powered personal assistant for automating online tasks",
    "rating": 4.4
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1723593359744-7e2d000f090d6f15.png",
    "title": "Agent Q",
    "description": "Autonomous AI agent for complex web-based tasks and self-healing processes.",
    "rating": 4.3
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1720412302891-ff450daa3fd980e6.png",
    "title": "Aomni",
    "description": "AI agent that conducts web research and generates account strategies for sales teams",
    "rating": 4.2
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1726180200498-118500a66b3d738f.jpg",
    "title": "Glean",
    "description": "AI-powered enterprise search and knowledge management platform",
    "rating": 4.1
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1720408797739-2bf1817fc26f2382.jpg",
    "title": "Do Anything Machine",
    "description": "AI agent that prioritizes and completes tasks autonomously to streamline your workflow.",
    "rating": 4.0
  },
  {
    "logoUrl": "https://storage.googleapis.com/aiagents_1/agent-logos/1720409753549-d1d0b7cea4a124e0.png",
    "title": "MultiOn",
    "description": "AI agents that autonomously perform web-based tasks on your behalf",
    "rating": 4.0
  }
];

const DiscoverAgent = () => {
  return (
    <Container fluid className="mt-4">
      <Row className="justify-content-center">
        <Col lg={5} md={12}>
          <AgentTable title="Coding" data={sampleDataCoding} />
        </Col>
        <Col lg={5} md={12}>
          <AgentTable title="Productivity" data={sampleDataProductivity} />
        </Col>
      </Row>
    </Container>
  );
};

export default DiscoverAgent;
