import React, { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { AuthContext } from '../contexts/AuthContext';
import AuthModal from './AuthModal';
import api from '../api';

const NavigationBar = () => {
  const {
    authToken,
    username,
    logout,
    showLoginModal,
    // showSignupModal,
  } = useContext(AuthContext);
  const isAuthenticated = !!authToken;
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await api.post('/users/logout/');
    } catch (error) {
      if (error.response && error.response.status !== 401) {
        console.error('Logout failed:', error);
        return;
      }
    }
    logout();
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            AIXpert
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* Left-aligned navigation links */}
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/battle-royale">
                Battle Royale
              </Nav.Link>
              <Nav.Link as={NavLink} to="/duel">
                Duel
              </Nav.Link>
              <Nav.Link as={NavLink} to="/chat">
                Chat
              </Nav.Link>
              <Nav.Link as={NavLink} to="/peer-review">
                Peer Review
              </Nav.Link>
              <Nav.Link as={NavLink} to="/leaderboard">
                Leaderboard
              </Nav.Link>
              <Nav.Link as={Link} to="/discover-agent">
                Discover Agent
              </Nav.Link>
            </Nav>
            <Nav>
              {isAuthenticated ? (
                <div className="d-flex align-items-center">
                  <span
                    className="text-light me-3 cursor-pointer"
                    onClick={handleProfileClick}
                    style={{ cursor: 'pointer' }}
                  >
                    {username}
                  </span>
                  <Button variant="outline-light" onClick={handleLogout}>
                    Log Out
                  </Button>
                </div>
              ) : (
                <>
                  {/* <Button
                    variant="outline-light"
                    className="me-2 ms-auto"
                    onClick={showSignupModal}
                  >
                    Sign Up
                  </Button> */}
                  <Button
                    variant="outline-light"
                    onClick={showLoginModal}
                  >
                    Log In
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <AuthModal />
    </>
  );
};

export default NavigationBar;
