import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Alert } from 'react-bootstrap';

const sampleData = {
  "data": {
    "headers": ["Rank* (UB)", "Model", "Arena Score", "95% CI", "Votes", "Organization", "License", "Knowledge Cutoff"],
    "datatype": ["number", "markdown", "number", "str", "number", "str", "str", "str"],
    "elem_id": "arena_leaderboard_dataframe",
    "height": 1000,
    "column_widths": ["70px", "220px", "90px", "90px", "90px", "120px", "150px", "100px"],
    "wrap": true,
    "value": {
      "headers": ["Rank* (UB)", "Model", "Arena Score", "95% CI", "Votes", "Organization", "License", "Knowledge Cutoff"],
      "data": [
        ["1","<a target=\"_blank\" href=\"https://platform.openai.com/docs/models/o1\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">o1-preview</a>","1339","+6/-7",9169,"OpenAI","Proprietary","2023/10"],
        ["1","<a target=\"_blank\" href=\"https://help.openai.com/en/articles/9624314-model-release-notes\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">ChatGPT-4o-latest (2024-09-03)</a>","1337","+4/-4",16685,"OpenAI","Proprietary","2023/10"],
        ["3","<a target=\"_blank\" href=\"https://platform.openai.com/docs/models/o1\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">o1-mini</a>","1314","+6/-5",9136,"OpenAI","Proprietary","2023/10"],
        ["4","<a target=\"_blank\" href=\"https://aistudio.google.com/app/prompts/new_chat?model=gemini-1.5-pro-exp-0827\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Gemini-1.5-Pro-Exp-0827</a>","1299","+4/-3",31928,"Google","Proprietary","2023/11"],
        ["4","<a target=\"_blank\" href=\"https://x.ai/blog/grok-2\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Grok-2-08-13</a>","1293","+4/-3",27731,"xAI","Proprietary","2024/3"],
        ["6","<a target=\"_blank\" href=\"https://openai.com/index/hello-gpt-4o/\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">GPT-4o-2024-05-13</a>","1285","+3/-3",93428,"OpenAI","Proprietary","2023/10"],
        ["7","<a target=\"_blank\" href=\"https://openai.com/index/gpt-4o-mini-advancing-cost-efficient-intelligence/\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">GPT-4o-mini-2024-07-18</a>","1272","+3/-3",33166,"OpenAI","Proprietary","2023/10"],
        ["7","<a target=\"_blank\" href=\"https://www.anthropic.com/news/claude-3-5-sonnet\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Claude 3.5 Sonnet</a>","1269","+3/-3",67165,"Anthropic","Proprietary","2024/4"],
        ["7","<a target=\"_blank\" href=\"https://aistudio.google.com/app/prompts/new_chat?model=gemini-1.5-flash-exp-0827\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Gemini-1.5-Flash-Exp-0827</a>","1269","+3/-4",25027,"Google","Proprietary","2023/11"],
        ["7","<a target=\"_blank\" href=\"https://x.ai/blog/grok-2\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Grok-2-Mini-08-13</a>","1268","+4/-4",24956,"xAI","Proprietary","2024/3"],
        ["7","<a target=\"_blank\" href=\"https://gemini.google.com/advanced\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Gemini Advanced App (2024-05-14)</a>","1266","+3/-3",52218,"Google","Proprietary","Online"],
        ["7","<a target=\"_blank\" href=\"https://ai.meta.com/blog/meta-llama-3-1/\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Meta-Llama-3.1-405b-Instruct-bf16</a>","1266","+6/-7",8787,"Meta","Llama 3.1 Community","2023/12"],
        ["7","<a target=\"_blank\" href=\"https://ai.meta.com/blog/meta-llama-3-1/\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Meta-Llama-3.1-405b-Instruct-fp8</a>","1266","+4/-4",33654,"Meta","Llama 3.1 Community","2023/12"],
        ["8","<a target=\"_blank\" href=\"https://platform.openai.com/docs/models/gpt-4o\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">GPT-4o-2024-08-06</a>","1264","+4/-3",25215,"OpenAI","Proprietary","2023/10"],
        ["10","<a target=\"_blank\" href=\"https://qwenlm.github.io/blog/qwen2.5/\" style=\"color: var(--link-text-color); text-decoration: underline;text-decoration-style: dotted;\">Qwen2.5-72b-Instruct</a>","1257","+8/-7",6017,"Alibaba","Qwen","2024/9"],
      ]
    }
  }
};

const Leaderboard = () => {
  const [language, setLanguage] = useState('English');
  const [category, setCategory] = useState('General');
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // In a real application, you would fetch and filter data based on language and category
    // For this example, we'll use the provided sample data without actual filtering
    try {
      setFilteredData(sampleData.data.value.data);
      setError('');
    } catch (err) {
      setError('Failed to load leaderboard data.');
    }
  }, [language, category]);

  return (
    <Container className="mt-4">
      <h2 className="text-center">Leaderboard</h2>

      <Row className="my-4">
        <Col md={6}>
          <Form.Group controlId="languageSelect">
            <Form.Label>Language</Form.Label>
            <Form.Control as="select" value={language} onChange={(e) => setLanguage(e.target.value)}>
              <option>English</option>
              <option>Chinese</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="categorySelect">
            <Form.Label>Category</Form.Label>
            <Form.Control as="select" value={category} onChange={(e) => setCategory(e.target.value)}>
              <option>General</option>
              <option>Coding</option>
              <option>Math</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      {error && <Alert variant="danger">{error}</Alert>}

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Model</th>
            <th>Score</th>
            <th>95% CI</th>
            <th>Organization</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item[0]}</td>
              <td dangerouslySetInnerHTML={{ __html: item[1] }} />
              <td>{item[2]}</td>
              <td>{item[3]}</td>
              <td>{item[5]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Leaderboard;