import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import useChatLogic from '../hooks/useChatLogic';
import PromptForm from '../components/PromptForm';
import AgentSelector from '../components/AgentSelector';
import ResponseCard from '../components/ResponseCard';

const Chat = ({ anonymous }) => {
  const {
    userPrompt,
    setUserPrompt,
    agents,
    selectedAgents,
    setSelectedAgents,
    responses,
    agentNames,
    feedback,
    setFeedback,
    error,
    isLoading,
    handleSend,
    handleUseRandomQuestion,
    handleFeedbackSubmit,
    successMessage,
  } = useChatLogic({ anonymous, numAgents: 1 });

  return (
    <Container className="mt-4">
      <h2 className="text-center">Chat with an Agent</h2>

      {error && <Alert variant="danger">{error}</Alert>}

      {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}

      <PromptForm
        userPrompt={userPrompt}
        setUserPrompt={setUserPrompt}
        handleSend={handleSend}
        handleUseRandomQuestion={handleUseRandomQuestion}
      />

      {!anonymous && (
        <AgentSelector
          agents={agents}
          selectedAgents={selectedAgents}
          setSelectedAgents={setSelectedAgents}
          numAgents={1}
        />
      )}

      <Row className="mt-4">
        {responses.map((resp, index) => (
          <Col md={12} key={index}>
            <ResponseCard
              agentName={agentNames[index]}
              response={resp}
              isLoading={isLoading}
              feedback={feedback[index] || ''}
              setFeedback={(fb) => setFeedback({ ...feedback, [index]: fb })}
              onSubmit={() => handleFeedbackSubmit(index)}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Chat;
