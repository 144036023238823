import React, { useState } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import api from '../api';

const ShareButton = ({ comparisonId }) => {
  const [showModal, setShowModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [error, setError] = useState('');
  const [isCopying, setIsCopying] = useState(false);

  const handleShare = async () => {
    try {
      const response = await api.post(`/shares/comparison/create/`, { comparison: comparisonId });
      const { uuid } = response.data;
      const currentBaseUrl = window.location.origin;
      setShareUrl(`${currentBaseUrl}/share/${uuid}`);
      setShowModal(true);
      setError('');
    } catch (err) {
      console.error('Failed to create share link:', err);
      setError('Failed to create share link. Please try again.');
    }
  };

  const handleCopy = async () => {
    try {
      setIsCopying(true);
      await navigator.clipboard.writeText(shareUrl);
      setIsCopying(false);
    } catch (err) {
      console.error('Failed to copy:', err);
      setError('Failed to copy the URL. Please try manually.');
      setIsCopying(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShareUrl('');
    setError('');
  };

  if (!comparisonId) {
    return null;
  }

  return (
    <>
      <Button variant="primary" onClick={handleShare}>
        Share
      </Button>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share the Battle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {shareUrl && (
            <>
              <p>Share this URL:</p>
              <input
                type="text"
                className="form-control mb-3"
                value={shareUrl}
                readOnly
              />
              <Button variant="secondary" onClick={handleCopy} disabled={isCopying}>
                {isCopying ? 'Copying...' : 'Copy URL'}
              </Button>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareButton;