import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const ResponseCard = ({ index, agentName, response, isLoading, feedback, setFeedback, onSubmit }) => (
  <Card className="mb-3">
    <Card.Header>
      {agentName || `Agent ${String.fromCharCode(65 + index)}`}
    </Card.Header>
    <Card.Body>
      <div>
        {isLoading ? 'Awaiting response...' : <ReactMarkdown>{response || ''}</ReactMarkdown>}
      </div>
      {feedback !== undefined && agentName !== undefined && response && (
        <>
          <Form.Group controlId={`feedback-${agentName}`} className="mt-3">
            <Form.Label>Provide detailed feedback for {agentName}:</Form.Label>
            <Form.Control
              as="textarea"
            rows={2}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Enter your feedback..."
            />
          </Form.Group>
          <Button
            variant="primary"
            className="mt-3"
            onClick={onSubmit}
            disabled={!feedback.trim()}
            block={+true}
          >
            Submit Feedback
          </Button>
        </>
      )}
    </Card.Body>
  </Card>
);

export default ResponseCard;
