import React from 'react';
import { Form, Button } from 'react-bootstrap';

const PromptForm = ({
  userPrompt,
  setUserPrompt,
  handleSend,
  handleUseRandomQuestion,
}) => (
  <Form onSubmit={(e) => e.preventDefault()}>
    <Form.Group controlId="userPrompt">
      <Form.Label>Enter your prompt:</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        value={userPrompt}
        onChange={(e) => setUserPrompt(e.target.value)}
        placeholder="Type your question here..."
      />
    </Form.Group>

    <div className="d-flex justify-content-between mt-2">
      <Button variant="primary" onClick={handleSend} className="me-2">
        Send
      </Button>
      <div className="flex-grow-1"></div>
      <Button variant="secondary" onClick={handleUseRandomQuestion}>
        Use Random Question
      </Button>
    </div>
  </Form>
);

export default PromptForm;
