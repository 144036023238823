import React, { createContext, useState, useCallback, useEffect } from 'react';
import api from '../api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authType, setAuthType] = useState('login');
  const [username, setUsername] = useState(localStorage.getItem('username'));

  useEffect(() => {
    if (authToken) {
      api.defaults.headers['Authorization'] = `Token ${authToken}`;
    } else {
      delete api.defaults.headers['Authorization'];
    }
  }, [authToken]);

  const login = useCallback((token, username) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('username', username);
    setAuthToken(token);
    setUsername(username);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('username');
    setAuthToken(null);
    setUsername(null);
  }, []);

  const showLoginModal = useCallback(() => {
    setAuthType('login');
    setShowAuthModal(true);
  }, []);

  const showSignupModal = useCallback(() => {
    setAuthType('signup');
    setShowAuthModal(true);
  }, []);

  const hideAuthModal = useCallback(() => {
    setShowAuthModal(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        username,
        login,
        logout,
        showAuthModal,
        showLoginModal,
        showSignupModal,
        hideAuthModal,
        authType,
        setAuthType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
