import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import '../App.css';

const StarRating = ({ rating }) => {
  const totalStars = 5;
  let fullStars = Math.floor(rating);
  const decimal = rating - fullStars;
  let halfStars = 0;

  if (decimal >= 0.75) {
    halfStars = 0;
    fullStars += 1;
  } else if (decimal >= 0.25) {
    halfStars = 1;
  }

  const emptyStars = totalStars - fullStars - halfStars;

  return (
    <div className="star-rating d-flex align-items-center">
      {Array.from({ length: fullStars }, (_, index) => (
        <FaStar key={`full-${index}`} color="#ffc107" />
      ))}
      {Array.from({ length: halfStars }, (_, index) => (
        <FaStarHalfAlt key={`half-${index}`} color="#ffc107" />
      ))}
      {Array.from({ length: emptyStars }, (_, index) => (
        <FaRegStar key={`empty-${index}`} color="#ffc107" />
      ))}
      <span className="ms-2">{rating.toFixed(1)}</span>
    </div>
  );
};

export default StarRating;
