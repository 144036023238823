import React, { useState, useEffect } from 'react';
import { Container, Card, Alert } from 'react-bootstrap';
import api from '../api';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/users/profile/?format=json');
        setProfile(response.data);
      } catch (err) {
        setError('Failed to load profile. Please try again later.');
        console.error('Error fetching profile:', err);
      }
    };

    fetchProfile();
  }, []);

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header as="h2">User Profile</Card.Header>
        <Card.Body>
          {/* <Card.Title>{profile.user.username}</Card.Title> */}
          <Card.Text>
            <strong>Username:</strong> {profile.user.username}
          </Card.Text>
          <Card.Text>
            <strong>Point Balance:</strong> {profile.pointBalance}
          </Card.Text>
          <Card.Text>
            <strong>Referral Code:</strong> {profile.referralCode}
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Profile;