import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import NavigationBar from './components/Navbar';
import BattleRoyale from './pages/BattleRoyale';
import Chat from './pages/Chat';
import PeerReview from './pages/PeerReview';
import Leaderboard from './pages/Leaderboard';
import DiscoverAgent from './pages/DiscoverAgent';
import Profile from './pages/Profile';
import DiscordCallback from './pages/DiscordCallback';

function App() {
  return (
    <AuthProvider>
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Navigate to="/battle-royale" />} />
          <Route path="/battle-royale" element={<BattleRoyale key="battle-royale" anonymous={true} />} />
          <Route path="/duel" element={<BattleRoyale key="duel" anonymous={false} />} />
          <Route path="/share/:uuid" element={<BattleRoyale key="share-battle-royale" anonymous={false} />} />
          <Route path="/chat" element={<Chat anonymous={false} />} />
          <Route path="/peer-review" element={<PeerReview />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/discover-agent" element={<DiscoverAgent />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/auth/discord/callback" element={<DiscordCallback />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
