import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Card, Alert, Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import api from '../api';
import { AuthContext } from '../contexts/AuthContext';

const PeerReview = () => {
  const [feedbackData, setFeedbackData] = useState(null);
  const [error, setError] = useState('');
  const { showLoginModal } = useContext(AuthContext);

  const fetchRandomFeedback = useCallback(async () => {
    try {
      const response = await api.get('/feedback/random/');
      setFeedbackData(response.data);
      setError('');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        showLoginModal();
      } else {
        setError('Failed to fetch feedback.');
      }
    }
  }, [showLoginModal]);

  useEffect(() => {
    fetchRandomFeedback();
  }, [fetchRandomFeedback]);

  const handleReviewSubmit = async (isUseful) => {
    try {
      await api.post('/peerreviews/submit/', {
        feedback: feedbackData.feedback.id,
        is_useful: isUseful,
      });
      setError('');
      fetchRandomFeedback();
    } catch (err) {
      setError('Failed to submit review.');
    }
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center">Peer Review</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {feedbackData ? (
        <Card className="mt-4">
          <Card.Header>Feedback from another user:</Card.Header>
          <Card.Body>
            <h5>User question:</h5>
            <Card.Text>{feedbackData.question.text}</Card.Text>
            <h5>Agent response by {feedbackData.response.agent.name}:</h5>
            <div>
              <ReactMarkdown>{feedbackData.response.text}</ReactMarkdown>
            </div>
            <h5>User feedback:</h5>
            <Card.Text>{feedbackData.feedback.text}</Card.Text>
            <div className="d-flex justify-content-start mt-3">
              <Button variant="success" onClick={() => handleReviewSubmit(true)} className="me-3">
                👍 Feedback is useful
              </Button>
              <Button variant="danger" onClick={() => handleReviewSubmit(false)} className="me-3">
                👎 Feedback is not useful
              </Button>
              <Button variant="secondary" onClick={fetchRandomFeedback}>
                I'm not sure
              </Button>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <p className="text-center">Loading feedback...</p>
      )}
    </Container>
  );
};

export default PeerReview;
