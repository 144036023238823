import React from 'react';
import { Button } from 'react-bootstrap';

const ComparisonButtons = ({ agentAName, agentBName, onCompare }) => (
  <div className="d-flex flex-column mt-8">
    <div className="d-flex justify-content-between mb-2">
      <Button variant="success" onClick={() => onCompare('R1')} className="w-50 me-2">
        👈 {agentAName || 'Agent A'} is better
      </Button>
      <Button variant="success" onClick={() => onCompare('R2')} className="w-50">
        👉 {agentBName || 'Agent B'} is better
      </Button>
    </div>
    <div className="d-flex justify-content-between">
      <Button variant="info" onClick={() => onCompare('TIE')} className="w-50 me-2">
        💎 Tie
      </Button>
      <Button variant="danger" onClick={() => onCompare('BAD')} className="w-50">
        👎 Both are bad
      </Button>
    </div>
  </div>
);

export default ComparisonButtons;
