import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import useChatLogic from '../hooks/useChatLogic';
import PromptForm from '../components/PromptForm';
import AgentSelector from '../components/AgentSelector';
import ResponseCard from '../components/ResponseCard';
import ComparisonButtons from '../components/ComparisonButtons';
import ShareButton from '../components/ShareButton';

const BattleRoyale = ({ anonymous }) => {
  const { uuid } = useParams();

  const {
    userPrompt,
    setUserPrompt,
    agents,
    selectedAgents,
    setSelectedAgents,
    responses,
    responseIds,
    agentNames,
    feedback,
    setFeedback,
    comparisonResult,
    comparisonId,
    error,
    isLoading,
    handleSend,
    handleUseRandomQuestion,
    handleComparisonSubmit,
    handleFeedbackSubmit,
    successMessage,
    initializeFromUUID,
  } = useChatLogic({ anonymous, numAgents: 2 });

  // Initialize the comparison from UUID if present
  React.useEffect(() => {
    if (uuid) {
      initializeFromUUID(uuid);
    }
  }, [uuid, initializeFromUUID]);

  return (
    <Container className="mt-4">
      <h2 className="text-center">Agent 1v1</h2>

      {error && <Alert variant="danger">{error}</Alert>}
      
      {successMessage && <Alert variant="success" className="mt-3">{successMessage}</Alert>}
      
      <PromptForm
        userPrompt={userPrompt}
        setUserPrompt={setUserPrompt}
        handleSend={handleSend}
        handleUseRandomQuestion={handleUseRandomQuestion}
      />

      {!anonymous && (
        <AgentSelector
          agents={agents}
          selectedAgents={selectedAgents}
          setSelectedAgents={setSelectedAgents}
          numAgents={2}
        />
      )}

      <Row className="mt-4">
        {responses.map((resp, index) => (
          <Col md={6} key={index}>
            <ResponseCard
              index={index}
              agentName={agentNames[index]}
              response={resp}
              isLoading={isLoading}
              feedback={feedback[index] || ''}
              setFeedback={(fb) => setFeedback({ ...feedback, [index]: fb })}
              onSubmit={() => handleFeedbackSubmit(index)}
            />
          </Col>
        ))}
      </Row>

      {responseIds.length === 2 && !isLoading && !comparisonResult && (
        <ComparisonButtons
          agentAName={agentNames[0]}
          agentBName={agentNames[1]}
          onCompare={handleComparisonSubmit}
        />
      )}

      {comparisonId && <ShareButton comparisonId={comparisonId} />}
    </Container>
  );
};

export default BattleRoyale;