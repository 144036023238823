import React from 'react';
import { Table } from 'react-bootstrap';
import StarRating from './StarRating';
import '../App.css';

const AgentTable = ({ title, data }) => {
  return (
    <div className="agent-table">
      <h3 className="table-title text-center">{title}</h3>
      <Table bordered hover className="custom-table">
        <tbody>
          {data.map((agent, index) => (
            <tr key={index} className="data-row">
              <td className="align-middle">
                <img src={agent.logoUrl} alt={`${agent.title} logo`} className="agent-logo" />
              </td>
              <td className="align-middle">
                <div className="agent-info">
                  <div className="agent-title">{agent.title}</div>
                  <div className="agent-description">{agent.description}</div>
                </div>
              </td>
              <td className="align-middle">
                <StarRating rating={agent.rating} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AgentTable;
