import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../api';
import { AuthContext } from '../contexts/AuthContext';

const AuthModal = () => {
  const {
    showAuthModal,
    hideAuthModal,
    authType,
    // setAuthType,
    login,
  } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [, setError] = useState('');

  const isLogin = authType === 'login';

  const resetForm = () => {
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setReferralCode('');
    setError('');
  };

  const hideAuthModalAndResetForm = () => {
    hideAuthModal();
    resetForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const endpoint = isLogin ? '/users/login/' : '/users/signup/';
      const data = { username, password };
      if (!isLogin) {
        data.referral = referralCode;
      }
      const response = await api.post(endpoint, data);
      if (isLogin) {
        login(response.data.token, username);
      }
      hideAuthModalAndResetForm();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.messages) {
        setError(err.response.data.messages.join('\n'));
      } else {
        setError('Authentication failed. Please try again.');
      }
    }
  };

  const handleDiscordLogin = () => {
    const discordClientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URI;
    const scope = 'identify email';
    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;
    window.location.href = discordAuthUrl;
  };

  return (
    <Modal show={showAuthModal} onHide={hideAuthModalAndResetForm} centered>
      <Modal.Header closeButton>
        <Modal.Title>{isLogin ? 'Log In' : 'Sign Up'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* <Form.Group controlId="formBasicUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          {!isLogin && (
            <Form.Group controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
          )}

          {!isLogin && (
            <Form.Group controlId="formBasicReferralCode" className="mt-3">
              <Form.Label>Referral Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Referral code"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </Form.Group>
          )}

          {error && (
            <Alert variant="danger" className="mt-3">
              {error.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < error.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </Alert>
          )}

          <Button variant="primary" type="submit" block={+true} className="mt-3">
            {isLogin ? 'Log In' : 'Sign Up'}
          </Button>

          <div>
            <Button
                variant="secondary"
                onClick={() => setAuthType(isLogin ? 'signup' : 'login')}
                block={+true}
                className="mt-3"
            >
              {isLogin ? 'Don\'t have an account? Sign Up' : 'Already have an account? Log in'}
            </Button>
          </div> */}

          <div className="d-flex justify-content-center">
            <Button
              variant="outline-primary"
              onClick={handleDiscordLogin}
              block={+true}
              className="mt-3"
              style={{ backgroundColor: '#5865F2', borderColor: '#5865F2', color: 'white', width: 'auto' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img
                  src="/discord-mark-white.png"
                  alt="Discord Logo"
                  style={{ width: '26px', height: '20px', marginRight: '10px' }}
                />
                Login with Discord
              </div>
            </Button>
          </div>
          
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
