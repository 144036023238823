import { useContext, useEffect, useCallback, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import api from '../api';

const DiscordCallback = () => {
  const { login } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const hasAuthenticated = useRef(false);

  const discordLogin = useCallback(async () => {
    if (hasAuthenticated.current) {
      return;
    }
    hasAuthenticated.current = true;
    try {
      const response = await api.post('/users/discord/login/', { code });
      login(response.data.token, response.data.username);
      navigate('/');
    } catch (error) {
      console.error('Error during Discord authentication:', error);
      if (error.response) {
        console.error('Error response body:', error.response.data);
      }
    }
  }, [code, login, navigate]);

  useEffect(() => {
    discordLogin();
  }, [discordLogin]);

  return (
    <div>
      Logging in with Discord...
    </div>
  );
};

export default DiscordCallback;
