import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const AgentSelector = ({ agents, selectedAgents, setSelectedAgents, numAgents }) => {
  const handleChange = (index, value) => {
    const newSelectedAgents = [...selectedAgents];
    newSelectedAgents[index] = value;
    setSelectedAgents(newSelectedAgents);
  };

  return (
    <Row className="mt-3">
      {[...Array(numAgents)].map((_, idx) => (
        <Col md={12 / numAgents} key={idx}>
          <Form.Group controlId={`selectAgent${idx + 1}`}>
            <Form.Label>Select Agent {numAgents > 1 ? String.fromCharCode(65 + idx) : ''}:</Form.Label>
            <Form.Control
              as="select"
              value={selectedAgents[idx] || ''}
              onChange={(e) => handleChange(idx, e.target.value)}
            >
              <option value="">-- Select Agent {numAgents > 1 ? String.fromCharCode(65 + idx) : ''} --</option>
              {agents.map(agent => (
                <option key={agent.id} value={agent.id}>
                  {agent.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      ))}
    </Row>
  );
};

export default AgentSelector;
